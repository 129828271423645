import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}

export const getOldMasterChefAddress = () => {
  return getAddress(addresses.masterChefOld)
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
}
export const getReferralAddress = () => {
  return getAddress(addresses.referral)
}
export const getNFTSignatureFactoryAddress = () => {
  return getAddress(addresses.signatureFactory)
}
export const getNFTSignatureMintProxyAddress = () => {
  return getAddress(addresses.signatureMintProxy)
}
export const getNFTSignatureRewardAddress = () => {
  return getAddress(addresses.signatureReward)
}
export const getNFTFactoryAddress = () => {
  return getAddress(addresses.nftFactory)
}
export const getNFTMintroxyAddress = () => {
  return getAddress(addresses.nftMintProxy)
}
export const getOldGeneralNFTRewardAddress = () => {
  return getAddress(addresses.generalNFTRewardV3)
}
export const getGeneralNFTRewardAddress = () => {
  return getAddress(addresses.generalNFTRewardV4)
}
export const getNFTMarketplaceAddress = () => {
  return getAddress(addresses.nftMarketplace)
}
export const getGovernanceAddress = () => {
  return getAddress(addresses.governance)
}
export const getCommunityGovernanceAddress = () => {
  return getAddress(addresses.communityGovernance)
}

export const getAdminAddress = () => {
  return getAddress(addresses.admin)
}

export const getSaleFactoryAddress = () => {
  return getAddress(addresses.saleFactory)
}
