import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import farmsReducer from './farms'
import vaultsReducer from './vaults'
import poolsReducer from './pools'
import predictionsReducer from './predictions'
import profileReducer from './profile'
import teamsReducer from './teams'
import achievementsReducer from './achievements'
import blockReducer from './block'
import votingReducer from './voting'
import lotteryReducer from './lottery'
import infoReducer from './info'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactions from './transactions/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import lists from './lists/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'
import nftMarketReducer from './nftMarket/reducer'
import nftReducer from './nft'
import nftmarketplace from './nftMarketplace/reducer'
import governanceReducer from './governance'
import communityGovernanceReducer from './communityGovernance'
import launchpadReducer from './launchpad'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists', 'profile']

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    achievements: achievementsReducer,
    block: blockReducer,
    farms: farmsReducer,
    vaults: vaultsReducer,
    pools: poolsReducer,
    nft: nftReducer,
    predictions: predictionsReducer,
    profile: profileReducer,
    teams: teamsReducer,
    voting: votingReducer,
    lottery: lotteryReducer,
    info: infoReducer,
    nftMarket: nftMarketReducer,
    nftmarketplace,
    governance: governanceReducer,
    communityGovernance: communityGovernanceReducer,
    launchpad: launchpadReducer,
    // Exchange
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

store.dispatch(updateVersion())

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
